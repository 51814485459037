import { FC, createContext, useState } from 'react';
import { ModelArticle } from './Interfaces';
import { CollectionReference, DocumentData, collection, getDocs, orderBy, query } from 'firebase/firestore';
import { firestoreDB } from '../firebaseConfig';
import { useEffectOnce } from 'usehooks-ts';

interface Props {
  children: React.ReactNode;
}

export interface CurrentProjectInterface {
  id: string;
  active: boolean;
  name: string;
  description: string;
  image: string;
  progress: number;
}

interface ArticleContextInterface {
  articles: ModelArticle[];
  projects: CurrentProjectInterface[];
  loading: boolean;
  modelCollectionRef: CollectionReference<DocumentData>;
  currentProjectCollectionRef: CollectionReference<DocumentData>;
  loadData: () => Promise<void>;
}

export const ArticleContext = createContext({} as ArticleContextInterface);

const ArticleProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState<ModelArticle[]>([]);
  const [projects, setProjects] = useState<CurrentProjectInterface[]>([]);

  const modelCollectionRef = collection(firestoreDB, 'models');
  const currentProjectCollectionRef = collection(firestoreDB, 'projects');

  const loadData = async () => {
    const sortedQuery = query(modelCollectionRef, orderBy('date', 'desc'));
    const data = await getDocs(sortedQuery);

    const loadedArticles = data.docs.map(doc => ({
      ...(doc.data() as ModelArticle), id: doc.id,
    }));
    
    setArticles(loadedArticles);

    const sortedProjects = query(currentProjectCollectionRef, orderBy('dateAdded', 'desc'));
    const projectData = await getDocs(sortedProjects);
    setProjects(
      projectData.docs.map(doc => ({
        ...(doc.data() as CurrentProjectInterface), id: doc.id,
      })),
    );

    setLoading(false);
  };

  useEffectOnce(() => {
    loadData();
  });

  return (
    <ArticleContext.Provider value={{ articles, projects, loading, modelCollectionRef, currentProjectCollectionRef, loadData }}>
      {children}
    </ArticleContext.Provider>
  );
};

export default ArticleProvider;
