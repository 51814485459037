import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { faHammer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Radio, Input, DatePicker, Divider, Rate, InputNumber, Button, Image } from 'antd';
import { ModelArticle } from '../../constants/Interfaces';
import { FC, useContext, useEffect } from 'react';
import { addDoc, doc, updateDoc } from 'firebase/firestore';
import { ArticleContext } from '../../constants/ArticleContext';
import dayjs from 'dayjs';

interface Props {
  drawerOpen: (open: boolean) => void;
  article?: ModelArticle;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const ArticleComposer: FC<Props> = ({ drawerOpen, article }) => {
  const { modelCollectionRef, loadData } = useContext(ArticleContext);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, article]);

  const addItem = async (values: any) => {
    const fields = {
      active: values.visibility,
      slug: values.slug,
      title: values.title,
      body: values.body,
      images: values.images,
      fullResLink: values.fullResLink,
      date: values.date.toDate(),
      name: values.name,
      price: values.price,
      link: values.link,
      difficulty: values.difficulty,
      rating: values.rating,
      parts: values.parts,
      scale: values.scale,
      award: values.award || null,
    };

    if (article) {
      const itemDoc = doc(modelCollectionRef, article.id);
      await updateDoc(itemDoc, fields);
    } else {
      await addDoc(modelCollectionRef, fields);
    }

    form.resetFields();
    loadData();
    drawerOpen(false);
  };

  return (
    <Form form={form} onFinish={addItem} autoComplete='off'>
      <Form.Item label='Visibility' name='visibility' initialValue={article?.active || false}>
        <Radio.Group>
          <Radio value={true}>Active</Radio>
          <Radio value={false}>Hidden</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='Slug' name='slug' initialValue={article?.slug} required>
        <Input required />
      </Form.Item>

      <Form.Item label='Title' name='title' initialValue={article?.title} required>
        <Input required />
      </Form.Item>

      <Form.List name='body' initialValue={article?.body}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Article body' : ''}
                key={field.key}
              >
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Form.Item {...field} style={{ width: '100%' }}>
                    <Input.TextArea />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                Add paragraph
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List name='images' initialValue={article?.images}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Image URL' : ''}
                key={field.key}
              >
                <div style={{ display: 'flex', gap: '10px' }}>
                  {index !== 0 && fields.length > 1 && (
                    <Button icon={<ArrowUpOutlined />} onClick={() => move(index, index - 1)} />
                  )}
                  {fields.length !== 1 && index !== fields.length - 1 && (
                    <Button icon={<ArrowDownOutlined />} onClick={() => move(index, index + 1)} />
                  )}
                  <Form.Item {...field} style={{ width: '100%' }}>
                    <Input />
                  </Form.Item>
                  <Image src={form.getFieldValue('images')[field.key]} width={100} />
                  {fields.length > 1 ? (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                Add image
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label='Full Resolution Link'
        name='fullResLink'
        initialValue={article?.fullResLink}
        required
      >
        <Input required />
      </Form.Item>

      <Form.Item label='Date' name='date' initialValue={dayjs(article?.date.toDate())} required>
        <DatePicker />
      </Form.Item>

      <Divider />

      <Form.Item label='Name' name='name' initialValue={article?.name} required>
        <Input required />
      </Form.Item>

      <Form.Item label='Price' name='price' initialValue={article?.price} required>
        <InputNumber required />
      </Form.Item>

      <Form.Item label='Link' name='link' initialValue={article?.link} required>
        <Input required />
      </Form.Item>

      <Form.Item label='Difficulty' name='difficulty' initialValue={article?.difficulty} required>
        <Rate character={<FontAwesomeIcon icon={faHammer} />} />
      </Form.Item>

      <Form.Item label='Rating' name='rating' initialValue={article?.rating} required>
        <Rate allowHalf />
      </Form.Item>

      <Form.Item label='Parts' name='parts' initialValue={article?.parts} required>
        <InputNumber required />
      </Form.Item>

      <Form.Item label='Scale' name='scale' initialValue={article?.scale} required>
        <Input required />
      </Form.Item>

      <Form.Item label='Award' name='award' initialValue={article?.award}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Button block type='primary' htmlType='submit' icon={<SaveOutlined />}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ArticleComposer;
