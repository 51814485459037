import { FC } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Statistic,
  Typography,
} from "antd";
import { StatisticObject } from "../constants/Interfaces";
import { AmazonOutlined, YoutubeOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

interface Props {
  title: string;
  subTitle: string;
  poster: string;
  stats: StatisticObject[];
  description: string;
  amazonLink: string;
  youtubeLink: string;
}

const MovieFragment: FC<Props> = ({
  title,
  subTitle,
  poster,
  stats,
  description,
  amazonLink,
  youtubeLink,
}) => {
  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Image src={poster} preview={false} />
        </Col>

        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title>{title}</Title>
            <Title level={3}>{subTitle}</Title>

            <Row
              style={{
                marginTop: "10px",
                gap: "8px",
              }}
            >
              <Button
                icon={<AmazonOutlined />}
                href={amazonLink}
                target="_blank"
              >
                Watch on Amazon Prime Video
              </Button>
              <Button
                icon={<YoutubeOutlined />}
                href={youtubeLink}
                target="_blank"
              >
                Watch on YouTube
              </Button>
            </Row>

            <Divider />

            <Row>
              {stats.map((stat) => (
                <Card key={stat.title} style={{ margin: "5px" }}>
                  <Statistic
                    title={stat.title}
                    value={stat.value}
                    prefix={stat.icon}
                  />
                </Card>
              ))}
            </Row>
          </div>
          <Divider />
          <Text>{description}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default MovieFragment;
